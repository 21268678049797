import React from "react";
import { Link, useLocation } from "react-router-dom";

import { images } from "../../constants";
import Button from "../Button/Button";

import styles from "./Navbar.module.scss";

const Navbar = ({ nav_options, nav_btns }) => {
  const location = useLocation(); // get the current location object

  // function to check if a given path is active
  const isActivePath = (path) => {
    return location.pathname === path;
  };

  return (
    <div className={styles.navbar}>
      <Link className={styles.logo}>
        {/* Use isActivePath to check if it's active */}
        <img src={images.aicalls_logo} alt="logo" />
      </Link>

      {/* <div className={`${styles.offer} ${styles.active}`}>
        Limited OFFER - Get additional 1500 mins FREE. Valid until 31st Januray
      </div> */}

      <div className={styles.buttons}>
        {
          // loop through the nav_options array and create a button for each item
          nav_options?.map((option, index) => (
            <Link
              to={option.link}
              target="_blank"
              key={index}
              className={
                isActivePath(option.path) ? styles.login_active : styles.login
              }
            >
              {/* Use isActivePath to check if it's active */}
              {option.title}
            </Link>
          ))
        }
        {/* <Button
          text={"BOOK DEMO"}
          link={"https://cal.com/ai-calls/ai-calls-30-min-videocall?date=2024-05-24&month=2024-05"}
        /> */}
      </div>
    </div>
  );
};

export default Navbar;
